import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {

    return (
        <div>
            <h1 className='text-2xl font-bold'>Our IT Services</h1>
            <div className='grid m-10 grid-cols lg:grid-cols-3 gap-1'>
                <Link>
                    <div className="card border-dotted border-2 border-gray-800 my-3 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/tx6wQNx/3533582.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">GRAPHIC DESIGN</h2>
                        </div>
                    </div>
                </Link>
                <Link to='/services/webservices'>
                    <div className="card my-3 border-dotted border-2 border-gray-800 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/7n3bW7N/19362653.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">WEB SERVICES</h2>
                        </div>
                    </div>
                </Link>
                <Link>
                    <div className="card my-3 border-dotted border-2 border-gray-800 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/RPw6f7s/digital-marketing-with-icons-business-people.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">DIGITAL MARKETING</h2>
                        </div>
                    </div>
                </Link>
                <Link>
                    <div className="card my-3 border-dotted border-2 border-gray-800 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/RQ3c08Y/1264.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">SOCIAL MEDIA MARKETING</h2>
                        </div>
                    </div>
                </Link>
                <Link>
                    <div className="card border-dotted border-2 border-gray-800 my-3 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/2FXZ88J/6471750.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">Domain & Hosting Services</h2>
                        </div>
                    </div>
                </Link>
                <Link>
                    <div className="card border-dotted border-2 border-gray-800 my-3 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/BTSywfp/onlinepayments.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">Payment Mathod Services</h2>
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    );
};

export default Services;