import React from 'react';
import { Link } from 'react-router-dom';

const OurServices = () => {
    return (
        <div>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                            Our Services
                        </p>
                    </div>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                                <defs>
                                    <pattern
                                        id="bc9273ce-29bb-4565-959b-714607d4d027"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#bc9273ce-29bb-4565-959b-714607d4d027)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Choose</span>
                        </span>{' '}
                        Your Service
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        Get Our Services And Develop Your Online Business Easily. 
                        100% Growup Your Business This Is Our Team Challenge.
                    </p>
                </div>
                <div className="grid max-w-md gap-10 row-gap-8 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
                    <div className=" flex flex-col transition duration-300 bg-white rounded shadow-sm hover:shadow">
                        <div className="relative w-full h-48">
                            <img
                                src="https://i.ibb.co/7n3bW7N/19362653.jpg"
                                className="object-cover w-full h-full rounded-t"
                                alt="Plan"
                            />
                        </div>
                        <div className="flex flex-col justify-between flex-grow p-8 border border-t-0 rounded-b">
                            <div>
                                <div className="text-lg font-semibold">WEB SERVICES DETAILS</div>
                                <p className="text-sm text-gray-900">
                               
                               * Suitable for personal use or small business as a starter website, 
                                <br />
                                * Professional Quality Website With CMS of your choice (ReactJS or Wordpress)
                                <br />
                                * Development time 1-2 weeks.
                                <br />
                                * Domain registration and hosting setup.
                                <br />
                                * Setup Live Chat Service (if You Need).
                                <br />
                                * Custom design, True responsive.
                                <br />
                                * Product & site search, Blog integration.
                                </p>
                                
                            </div>
                            <Link
                                to='/services/webservices'
                                className="my-2 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            >
                                CHECK DETAILS
                            </Link>
                        </div>
                    </div>
                    <div className=" flex flex-col transition duration-300 bg-white rounded shadow-sm hover:shadow">
                        <div className="relative w-full h-48">
                            <img
                                src="https://i.ibb.co/tx6wQNx/3533582.jpg"
                                className="object-cover w-full h-full rounded-t"
                                alt="Plan"
                            />
                        </div>
                        <div className="flex flex-col justify-between flex-grow p-8 border border-t-0 rounded-b">
                            <div>
                                <div className="text-lg font-semibold">GRAPHIC DESIGN DETAILS</div>
                                <p className="text-sm text-gray-900">
                                * Logo Design For (Website, Fb & Others)
                                <br /> 
                                * Business Card Design For Any Kind Of Business.
                                <br />
                                * Banner Design, Social Media Design.
                                <br />
                                * Template Design, Cover Page.
                                <br />
                                * Image Editing & Photo Retouch, 
                                <br />
                                * Thumbail, Background Removed, 
                                </p>
                        
                            </div>
                            <Link
                                to='/services'
                                className="my-2 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            >
                                CHECK DETAILS
                            </Link>
                        </div>
                    </div>
                    <div className=" flex flex-col transition duration-300 bg-white rounded shadow-sm hover:shadow">
                        <div className="relative w-full h-48">
                            <img
                                src="https://i.ibb.co/RPw6f7s/digital-marketing-with-icons-business-people.jpg"
                                className="object-cover w-full h-full rounded-t"
                                alt="Plan"
                            />
                        </div>
                        <div className="flex flex-col justify-between flex-grow p-8 border border-t-0 rounded-b">
                            <div>
                                <div className="text-lg font-semibold">DEGITAL MARKETING DETAILS</div>
                                <p className="text-sm text-gray-900">
                                * Rank In Google First.
                                <br />
                                * Keyword Analysis & SEO.
                                <br />
                                * On-page optimization. 
                                <br />
                                * Backlink (bloger, WEB 2.0).
                                <br />
                                * Content enhancement focusing on keyword.
                                <br />
                                * Backlink (bloger, WEB 2.0).
                                <br />
                                * Digital Ads Design & Management for paid ads
                                <br />
                                * Social Media Page Creation Fully Complete (Facebook, Twiter, Google Business, Linkdin, Youtube)
                                </p>
                            </div>
                            <Link
                                to='/services'
                                className="my-2 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            >
                                CHECK DETAILS
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;