import React from 'react';

const CallBackRequest = () => {
    return (
        <div>
            <section className="p-6 dark:bg-gray-800 dark:text-gray-100">
                <div className="container grid gap-6 mx-auto text-center lg:grid-cols-2 xl:grid-cols-5">
                    <div className="w-full px-6 py-16 rounded-md sm:px-12 md:px-16 xl:col-span-2 dark:bg-gray-900">
                        
                        <h1 className="text-4xl font-extrabold dark:text-gray-50">Call Back Request</h1>
                        <p className="my-8">
                            <span className="font-medium dark:text-gray-50">IF You Have Any Question: </span>Please Fill Up Our From And Our Agent Call Back at Your Time. Thanks For a Call Back Request.
                        </p>
                        <form noValidate="" action="" className="self-stretch space-y-3 ng-untouched ng-pristine ng-valid">
                            <div>
                                <label htmlFor="name" className="text-sm sr-only">Your Full Name</label>
                                <input id="name" type="text" placeholder="Your FUll Name" className="p-2 w-full rounded-md focus:ring focus:ring-violet-400 dark:border-gray-700 bg-gray-100"/>
                            </div>
                            <div>
                                <label htmlFor="phoneno" className="text-sm sr-only">Email address</label>
                                <input id="phoneno" type="text" placeholder="Phone No" className="p-2 w-full rounded-md focus:ring focus:ring-violet-400 dark:border-gray-700 bg-gray-100"/>
                            </div>
                            <div>
                                <label htmlFor="PhoneCallTime" className="text-sm sr-only">Your Free Time</label>
                                <input id="phonetime" type="text" placeholder=" 09:00 AM - 11:59 PM" className="p-2 w-full rounded-md focus:ring focus:ring-violet-400 dark:border-gray-700 bg-gray-100"/>
                            </div>
                            <button type="button" className=" btn w-full py-2 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Call Request</button>
                        </form>
                    </div>
                    <img src="https://i.ibb.co/Fh3TLYj/no-people-desk-with-multiple-computers-call-center-office-used-by-telemarketing-agents-answer-phone.jpg" alt="" className="object-cover w-full rounded-md xl:col-span-3 dark:bg-gray-500"/>
                </div>
            </section>
        </div>
    );
};

export default CallBackRequest;