import React from 'react';
import PricePakage from './PricePakage';
import ThreeStep from './ThreeStep';

const WebServices = () => {
    return (
        <div>
            <ThreeStep></ThreeStep>
            <PricePakage></PricePakage>
        </div>
    );
};

export default WebServices;