import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Login from './Components/Users/Login/Login';
import Register from './Components/Users/Register/Register';
import Navigation from './Components/Shared/Navigation';
import Services from './Components/Services/Services';
import Footer from './Components/Shared/Footer';
import ContactUs from './Components/ContactUs/ContactUs';
import ForgetPassword from './Components/Users/ForgetPassword/ForgetPassword';
import NotFound from './Components/NotFound/NotFound';
import WebServices from './Components/Services/WebServices/WebServices';
import PlaceOrder from './Components/Services/WebServices/PlaceOrder';
import Courses from './Components/Courses/Courses';
import WebDevelopment from './Components/Courses/WebDevelopment/WebDevelopment';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/courses" element={<Courses/>} />
        <Route path="/courses/webdevelopment" element={<WebDevelopment/>} />
        <Route path="/services/webservices" element={<WebServices/>} />
        <Route path="/services/webservices/order" element={<PlaceOrder/>} />
        <Route path="/contactus" element={<ContactUs/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forgetpassword" element={<ForgetPassword/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
