import React from 'react';
import './Loading.css';
const Loading = () => {
    return (
        <div>
            <span className="loader">Loading</span>
        </div>
    );
};

export default Loading;