import React from 'react';
import CallBackRequest from '../CallBackRequest/CallBackRequest';
import OurServices from '../OurServices/OurServices';
import WebsiteAnalysic from '../WebsiteAnalysic/WebsiteAnalysic';
import Header from './Header';

const Home = () => {
    return (
        <div>
            <Header></Header>
            <OurServices></OurServices>
            <CallBackRequest></CallBackRequest>
            <WebsiteAnalysic></WebsiteAnalysic>
        </div>
    );
};

export default Home;