import React from 'react';
import { Link } from 'react-router-dom';

const PricePakage = () => {
    return (
        <div>
            {/* <!-- ====== Pricing Section Start --> */}
            <div
                className="relative z-20 overflow-hidden bg-white pb-12 lg:pt-[120px] lg:pb-[90px]"
            >
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                            
                                <h2
                                    className="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]"
                                >
                                    Our Pricing Plan
                                </h2>
                                <p className="text-body-color text-base">
                                    Now You Can Read Carefully Our Services Details 
                                    And Choose Your Plan Then Just Click Your Plane
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-mx-4 flex flex-wrap justify-center">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="border-primary shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                            >
                                <span className="text-primary mb-4 block text-lg font-semibold">
                                    Personal
                                </span>
                                <h2 className="text-dark mb-5 text-[42px] font-bold">
                                    $50 / ৳6000 TK
                    
                                </h2>
                                <p
                                    className="text-body-color mb-8 border-b border-[#F2F2F2] pb-8 text-base"
                                >
                                    Make Parsonal Web app using React js, Node JS, Google Firebase
                                </p>
                                <div className="mb-7">
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Landing Page Design in React
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Any format to React, Figma to HTML CSS
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Single Page Web App Development
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Design customization, Source code
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Figma to HTML CSS, XD to HTML CSS
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Login & Register Only Email
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Full Web App with the backend of Firebase
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Static Website as per your design
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    API integrations( crud operations only)
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Server upload, Browser compatibility
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    On page SEO & 7 Days Delivery
                                    </p>
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    1 Months support, 5 Revisions
                                    </p>
                                </div>
                                <a
                                    href="javascript:void(0)"
                                    className="text-primary hover:bg-primary hover:border-primary block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold transition hover:text-white"
                                >
                                    Choose Personal
                                </a>
                                <div>
                                    <span className="absolute right-0 top-7 z-[-1]">
                                        <svg
                                            width="77"
                                            height="172"
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1="86"
                                                    y1="0"
                                                    x2="86"
                                                    y2="172"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                                                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span className="absolute right-4 top-4 z-[-1]">
                                        <svg
                                            width="41"
                                            height="89"
                                            viewBox="0 0 41 89"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="38.9138"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="1.42021"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 1.42021)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 1.4202)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="1.42019"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 1.42019)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 1.4202)"
                                                fill="#3056D3"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="border-primary shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                            >
                                <span className="text-primary mb-4 block text-lg font-semibold">
                                    Business
                                </span>
                                <h2 className="text-dark mb-5 text-[42px] font-bold">
                                    $249 / ৳29,999
                                    
                                </h2>
                                <p
                                    className="text-body-color mb-8 border-b border-[#F2F2F2] pb-8 text-base"
                                >
                                    Make Business Web app using React js, Node JS, Express, API(CRUD) & Firebase.
                                </p>
                                <div className="mb-7">
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Five Page Web App Development.
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Customization, Source code & Access.
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Figma to HTML CSS, XD to HTML CSS
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Login & Register (Email, Google, Facebook)
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Full Web App with the backend of Firebase
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Database Using MongoDB & MYSQL
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Static Website as per your design
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    API integrations( crud operations only)
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Server upload, Browser compatibility
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    On page SEO & 14 Days Delivery
                                    </p>
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    3 Months support, 15 Revisions
                                    </p>
                                </div>
                                <Link
                                    to='/services/webservices/order'
                                    className="bg-primary border-primary block w-full rounded-md border p-4 text-center text-base font-semibold text-white transition hover:bg-opacity-90"
                                >
                                    Choose Business
                                </Link>
                                <div>
                                    <span className="absolute right-0 top-7 z-[-1]">
                                        <svg
                                            width="77"
                                            height="172"
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1="86"
                                                    y1="0"
                                                    x2="86"
                                                    y2="172"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                                                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span className="absolute right-4 top-4 z-[-1]">
                                        <svg
                                            width="41"
                                            height="89"
                                            viewBox="0 0 41 89"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="38.9138"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="1.42021"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 1.42021)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 1.4202)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="1.42019"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 1.42019)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 1.4202)"
                                                fill="#3056D3"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="border-primary shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                            >
                                <span className="text-primary mb-4 block text-lg font-semibold">
                                    Professional
                                </span>
                                <h2 className="text-dark mb-5 text-[42px] font-bold">
                                    $899 / ৳99,999
                                    
                                </h2>
                                <p
                                    className="text-body-color mb-8 border-b border-[#F2F2F2] pb-8 text-base"
                                >
                                    Make Proffesonal or Ecommerce Websites Web app using React js, Node JS, Express, API, Mongodb & Firebase.
                                </p>
                                <div className="mb-7">
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Five Page or More Web App Development.
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Customization, Source code & Access.
                                    </p>
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Login & Register (Email, Google, Facebook)
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    SMM Top 10 Website Profile Complete.
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Full Web App with the backend of Firebase
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Database Using MongoDB & MYSQL
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Responsive Website as per your design
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    API integrations, Web app Fully Setup
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    Server upload, Browser compatibility.
                                    </p>
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    On page SEO & 1 Months Delivery.
                                    </p>
                                    
                                    <p className="text-body-color mb-1 text-base leading-loose">
                                    1 Year support, 50 Revisions.
                                    </p>
                                </div>
                                <Link
                                    to='/services/webservices/order'
                                    className="text-primary hover:bg-primary hover:border-primary block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold transition hover:text-white"
                                >
                                    Choose Professional
                                </Link>
                                <div>
                                    <span className="absolute right-0 top-7 z-[-1]">
                                        <svg
                                            width="77"
                                            height="172"
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1="86"
                                                    y1="0"
                                                    x2="86"
                                                    y2="172"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                                                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span className="absolute right-4 top-4 z-[-1]">
                                        <svg
                                            width="41"
                                            height="89"
                                            viewBox="0 0 41 89"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="38.9138"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="1.42021"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 1.42021)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 1.4202)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="1.42019"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 1.42019)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 1.4202)"
                                                fill="#3056D3"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
{/* <!-- ====== Pricing Section End --> */ }

        </div >
    );
};

export default PricePakage;