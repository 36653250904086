import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../Users/Firebase/Firebase.init';
import { signOut } from 'firebase/auth';

const Navigation = () => {
    const [user] = useAuthState(auth);
    const logout = () => {
        signOut(auth);
      };

    const manuItem = <>
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/services'>Service</Link></li>
        <li><Link to='/courses'>Courses</Link></li>
        <li><Link to='/contactus'>Contact Us</Link></li>
    </>
    return (
        <div>
            <div className="navbar bg-base-100">
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            {manuItem}
                        </ul>
                    </div>
                    <img className='w-72 h-12' src="https://i.imgur.com/Puhtxrt.png" alt="" />
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal p-0">
                        {manuItem}
                    </ul>
                </div>
                <div className="navbar-end">
                    {user?
                    <a className="btn"><Link onClick={logout} to='/login'>LOGOUT</Link></a>
                    :
                    <a className="btn"><Link to='/login'>LOGIN</Link></a>
                    }
                </div>
            </div>
        </div>
    );
};

export default Navigation;