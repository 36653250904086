import React from 'react';

const WebDevelopment = () => {
    return (
        <div className='bg-cyan-700'>
            <h1 className='text-white font-semibold text-4xl'>What You Will Learn_ From This Course?</h1>
        </div>
    );
};

export default WebDevelopment;