import React from 'react';
import { Link } from 'react-router-dom';

const Courses = () => {
    return (
        <div>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                            Our Courses
                        </p>
                    </div>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                                <defs>
                                    <pattern
                                        id="bc9273ce-29bb-4565-959b-714607d4d027"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#bc9273ce-29bb-4565-959b-714607d4d027)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Choose</span>
                        </span>{' '}
                        Your Courses
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        Join Our Courses And Develop Your Online Career. 
                        Complete Our Courses Then Get Your Jobs This Is Our Team Challenge.
                    </p>
                </div>

                <div>
            <div className='grid m-10 grid-cols lg:grid-cols-3 gap-20'>
                <Link>
                    <div className="card border-dotted border-2 border-gray-800 my-3 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/tx6wQNx/3533582.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">GRAPHIC DESIGN</h2>
                        </div>
                    </div>
                </Link>
                
                    <div className="card my-3 border-dotted border-2 border-gray-800 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/7n3bW7N/19362653.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <a className="card-title rounded-none btn" href="https://forms.gle/2QQFBawo7h5zRHii8 ">WEB DEVELOPMENT</a>
                        </div>
                    </div>
                
                <Link>
                    <div className="card my-3 border-dotted border-2 border-gray-800 w-96 bg-base-100 shadow-xl">
                        <figure className="w-fit h-60">
                            <img src='https://i.ibb.co/RPw6f7s/digital-marketing-with-icons-business-people.jpg' alt="pabna-it-solution-center-graphic-design" className="" />
                        </figure>
                        <div className="items-center text-center">
                            <h2 className="card-title rounded-none btn">DIGITAL MARKETING</h2>
                        </div>
                    </div>
                </Link>
                
            </div>

        </div>

            </div>
        </div>
    );
};

export default Courses;